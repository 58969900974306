import React from "react";
import { Icon } from "@iconify/react";

const Icons = () => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <span
          style={{
            color: "white",
            display: "inherit",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
        >
          {" "}
          <a href="https://www.facebook.com/profile.php?id=100071922004322">
            <Icon
              icon="akar-icons:facebook-fill"
              color="#d1c70b"
              width="30"
              height="30"
            />
          </a>
        </span>
        <span
          style={{
            color: "white",
            display: "inherit",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
        >
          {" "}
          <a href="https://www.instagram.com/boazpolani/">
            <Icon
              icon="akar-icons:instagram-fill"
              color="#d1c70b"
              width="30"
              height="30"
            />
          </a>
        </span>
        <span
          style={{
            color: "white",
            display: "inherit",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
        >
          <a href="https://api.whatsapp.com/send/?phone=%2B972545760576&text&type=phone_number&app_absent=0">
            <Icon icon="bi:whatsapp" color="#d1c70b" width="30" height="30" />
          </a>
        </span>

        <span
          style={{
            color: "white",
            display: "inherit",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
        >
          <a data-tel="0545760576" href="tel:0545760576">
            <Icon
              icon="bxs:phone-call"
              color="#d1c70b"
              width="30"
              height="30"
            />
          </a>
        </span>
      </div>
    </div>
  );
};
export default Icons;
