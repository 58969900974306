import React, { useEffect } from "react";
import "./StartNow.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import startnow from "../../data/image/startnow/startnow.png";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import bpxinggloves from "../../data/image/trainingschedule/boxing-gloves.png";
import Nav from "react-bootstrap/Nav";

import { Icon } from "@iconify/react";
const StartNow = () => {
  const check = () => {
    var checkBox = document.getElementById("checbox");
    var text1 = document.getElementsByClassName("text1");
    var text2 = document.getElementsByClassName("text2");

    for (var i = 0; i < text1.length; i++) {
      if (checkBox.checked === true) {
        text1[i].style.display = "block";
        text2[i].style.display = "none";
      } else if (checkBox.checked === false) {
        text1[i].style.display = "none";
        text2[i].style.display = "block";
      }
    }
  };
  useEffect(() => {
    check();
  });
  return (
    <div className="startnow_main">
      <div className="header_startnow">
        <img src={startnow} className="trainingschedule_img" />
      </div>

      <div className="rt-container">
        <div className="col-rt-12">
          <div className="Scriptcontent">
            {/* partial:index.partial.html */}
            <div className="container_startnow_main">
              <div className="top">
                <div className="toggle-btn">
                  <span style={{ margin: "0.8em" }}>מבוגר</span>
                  <label className="switch">
                    <input type="checkbox" id="checbox" onClick={check} />
                    <span className="slider round" />
                  </label>
                  <span style={{ margin: "0.8em" }}>נוער</span>
                </div>
              </div>
              <div className="package-container">
                <div className="packages">
                  <h1>שיעור ניסיון</h1>

                  <h2 className="text1 after_price">
                    מבוגרים{" "}
                    <span className="elementor-price-table__currency">
                      <p>₪40</p>
                    </span>
                  </h2>
                  <h2 className="text2 after_price">
                    נוער{" "}
                    <span className="elementor-price-table__currency">
                      <p>₪20</p>
                    </span>
                  </h2>

                  <h1>תשלום חד פעמי</h1>
                  <ul className="list">
                    <p>לוחם אמיתי לא מחכה - הוא פועל.</p>
                    <p>הירשם עכשיו ותתחיל להתאמן!</p>
                  </ul>
                  <a
                    style={{ color: "inherit" }}
                    href="https://api.whatsapp.com/send?phone=972545760576&text=%D7%94%D7%99%D7%99%20%D7%91%D7%95%D7%A2%D7%96,%20%D7%90%D7%A0%D7%99%20%D7%A8%D7%95%D7%A6%D7%94%20%D7%9C%D7%94%D7%99%D7%A8%D7%A9%D7%9D%20%D7%9C%D7%90%D7%99%D7%9E%D7%95%D7%9F%20%D7%A0%D7%99%D7%A1%D7%99%D7%95%D7%9F:"
                    className="button button1"
                  >
                    הירשם עכשיו
                  </a>
                </div>

                <div className="packages">
                  <h1>חודשי</h1>
                  <h2 className="text1 after_price">
                    מבוגרים{" "}
                    <span className="elementor-price-table__currency">
                      <p>₪350</p>
                    </span>
                  </h2>
                  <h2 className="text2 after_price">
                    נוער{" "}
                    <span className="elementor-price-table__currency">
                      <p>₪350</p>
                    </span>
                  </h2>

                  <h1>לוחם אמיתי !</h1>
                  <ul className="list">
                    <p>לוחם אמיתי לא מחכה - הוא פועל.</p>
                    <p>הירשם עכשיו ותתחיל להתאמן!</p>
                  </ul>
                  <a
                    style={{ color: "inherit" }}
                    href="https://api.whatsapp.com/send?phone=972545760576&text=%D7%94%D7%99%D7%99%20%D7%91%D7%95%D7%A2%D7%96,%20%D7%90%D7%A0%D7%99%20%D7%A8%D7%95%D7%A6%D7%94%20%D7%9C%D7%94%D7%99%D7%A8%D7%A9%D7%9D%20%D7%9C%D7%90%D7%99%D7%9E%D7%95%D7%9F%20%D7%A0%D7%99%D7%A1%D7%99%D7%95%D7%9F:"
                    className="button button3"
                  >
                    הירשם עכשיו
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartNow;
