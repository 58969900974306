import React from 'react'
import "./AboutMe.css"
import Button from 'react-bootstrap/Button';

const AboutMe = () => {
    return (
        <div className='aboutme'>
            <h1 className='elementor-heading-title'>בועז פולני</h1>

            <div className="elementor-widget-container">
                <div className="elementor-divider">
                    <span className="elementor-divider-separator">
                    </span>
                </div>
            </div>
            <p className='about_us_text' >

                <p><strong >גראנד מאסטר (Arjan yai) בעז פולני:<br />מקים ומנהל לומפיני ישראל הוא גראנד מאסטר בועז פולני.</strong></p>
                <br />
                <p  >מבכירי המאמנים בארץ. בועז פולני בעל וותק של למעלה מ-42 שנים<br /> באיגרוף תאילנדי וניסיון של מעל ל-25 שנים כמאמן.<br />
                </p>
                <p >בשנת 1987 היה בועז פולני מתאגרף מן המניין במנזר “פראקאו” בקבוצת המנזר – <br />“סאוואראפין” לתקופה של שש שנים ולחם בזירות מקצועניות בתאילנד<br />
                </p>
                <p >בשנת 1992 הוסמך כמאמן מטעם משרד החינוך התרבות והספורט בישראל ובשנת 2001 הוסמך כמאמן-על (מאסטר) בביה”ס מטעם המדינה WMTC בתאילנד.<br /></p>
                <p >בשנת 2002 הקים בועז פולני את מועדון לומפיני מתוך רצון לקדם את ענף האיגרוף התאילנדי בארץ.<br /> המועדון מונה כיום מספר סניפים פעילים בישראל שמהם יצאו אלופי אירופה ועולם.</p>
                <br />
                <div className='button_re_about_us' >
                    <div className="button_aboutme">
                        <Button variant="primary" size="lg" type="submit" style={{ "backgroundColor": "#d1c709", "border": "none" }}>
                            <a href='#registration' style={{ "color": "inherit" }}>   כן בועז , אני רוצה לבוא להתאמן!</a>


                        </Button>
                    </div>
                </div>
            </p>


        </div>
    )
}

export default AboutMe