import React, { useRef, useState } from "react";
import "./EndSite.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import MapGivatiom from "./MapGivatiom";
import MapTLV from "./MapTLV";
import Icons from "./Icons";
import emailjs from "@emailjs/browser";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
const EndSite = () => {
  const navigate = useNavigate();
  const form = useRef();
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(null);
  const handleCloseModal = () => {
    setShowModal(false);
    clearTimeout(timer);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_cs9pleq",
        "template_o48cxmu",
        form.current,
        "cdfqrlv42Baz6U-jk"
      )
      .then(
        (result) => {
          setShowModal(true);

          navigate("/ThankYou");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="endsite">
      <div className="endsite_header">
        <h1> יש לך עוד שאלה?</h1>
        <div className="elementor-widget_end_site">
          <div className="elementor-divider">
            <span className="elementor-divider-separator"></span>
          </div>
        </div>
      </div>
      <div className="input_main">
        <Form className=" input_endsite" ref={form} onSubmit={sendEmail}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              required
              type="text"
              placeholder="שם מלא"
              id="fullName"
              name="fullName"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              name="email"
              id="email"
              type="email"
              placeholder="אימייל"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              type="phone"
              placeholder="מספר פלאפון"
              id="phone"
              name="phone"
            />
          </Form.Group>

          <Button
            variant="primary"
            size="lg"
            type="submit"
            style={{
              backgroundColor: "#d1c709",
              width: "100%",
              border: "none",
            }}
          >
            שלח
          </Button>
        </Form>
      </div>
      <div className="maps">
        <MapGivatiom />
        <MapTLV />
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          <p className="modal-message">
            הודעה נשלחה בהצלחה! <br />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            סגור
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EndSite;
