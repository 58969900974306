import React from 'react'
import "./SuitsMe.css"
import header from "../../data/image/suitsme/Itsuitsme.png"

import { Icon } from '@iconify/react';
import video from "../../data/video/finalmovieheader.mp4"

const SuitsMe = () => {
    return (
        <div className='suitsme_main'>
            <div className='suitsme'>
                <img className='suitsme_header' src={header} />
                <p className='main_text'>
                    <p>אם אתה רוצה להיות לוחם זירה ואם את מבקשת ללמוד להגן על עצמך</p>
                    <p>או שאתה מחפש להיות יותר חזק ואולי את רוצה לשרוף קלוריות כדי לרזות</p>
                    <p><strong>איגרוף תאילנדי אצל מאסטר בועז פולני הוא בדיוק מה שחיפשתם.</strong></p>
                </p>

            </div>
            <div className='main_secondary'>
                <video className='main_secondary_video ' width="400" height="340" controls autoPlay muted >
                    <source src={video} type="video/mp4" />

                </video>
                <p className='check'>
                    <p> <Icon style={{ "marginBottom": "-7px", "color": "#d1c70b" }} icon="akar-icons:check" width="25" height="25" /> ילדים מגיל 11, נשים וגברים</p>
                    <p> <Icon style={{ "marginBottom": "-7px", "color": "#d1c70b" }} icon="akar-icons:check" width="25" height="25" /> אין צורך בכושר או בניסיון קודם</p>
                    <p> <Icon style={{ "marginBottom": "-7px", "color": "#d1c70b" }} icon="akar-icons:check" width="25" height="25" />למי שרוצה להתחזק ולהתחטב</p>
                    <p> <Icon style={{ "marginBottom": "-7px", "color": "#d1c70b" }} icon="akar-icons:check" width="25" height="25" />למי שרוצה לבנות ביטחון עצמי חזק</p>
                    <p> <Icon style={{ "marginBottom": "-7px", "color": "#d1c70b" }} icon="akar-icons:check" width="25" height="25" />למי שרוצה לדעת להגן על עצמו מול כל תוקף בכל רגע נתון</p>
                    <p> <Icon style={{ "marginBottom": "-7px", "color": "#d1c70b" }} icon="akar-icons:check" width="25" height="25" />למי שרוצה לעשות ספורט ללא משקולות...</p>
                    <p> <Icon style={{ "marginBottom": "-7px", "color": "#d1c70b" }} icon="akar-icons:check" width="25" height="25" />למי שרוצה לעבוד על גמישות וחיזוק מפרקים</p>
                    <p> <Icon style={{ "marginBottom": "-7px", "color": "#d1c70b" }} icon="akar-icons:check" width="25" height="25" />למי שרוצה להתחרות בזירה ארצית ובינלאומית</p>
                    <p> <Icon style={{ "marginBottom": "-7px", "color": "#d1c70b" }} icon="akar-icons:check" width="25" height="25" />למי שרוצה לרזות בלי לסבול...</p>

                </p>

            </div>
            <h3 className='check_last_title'> <strong>אם אתה עונה על אחד מהקריטריונים ברשימה זה מתאים לך!</strong> </h3>
            <div className='includ_text'>
                <div className='header_includ'>
                    <h1>אצלנו בלומפיני:</h1>
                    <div className="elementor-widget-header_includ"><div className="elementor-divider"><span className="elementor-divider-separator"></span></div></div>
                </div>
                <span className='ember'>
                    <p>אנחנו קודם כל בשביל הספורט. נכנסים לכושר ומשפרים את הקואורדינציה שלנו (כן, אפשר לשפר קואורדינציה…).</p>
                    <p>90 – 60 דקות אימון עם חבר והכי חשוב לשמור “ידיים על הראש” כדי לא לקבל מכה,</p>
                    <p>ככה אנחנו לומדים להגן על עצמנו, ובין אגרוף לבעיטה גם מרוויחים על הדרך תוספת ביטחון עצמי. </p>
                    <p>אצלנו אין אגו ולא צריך ידע או ניסיון קודם כדי להצטרף אלינו. </p>
                    <p>אנחנו בקבוצה מתאמנים מתחילים לצד לוחמי זירה וכל אחד מאיתנו מתאמן עם חבר שמתאים לרמה שלו. </p>

                    <p>אנחנו מאמינים שמצוינות היא לא פעולה אלא הרגל, ושאת הדברים שנעשה הכי הרבה – נעשה הכי טוב. </p>
                    <p>זאת הסיבה שאנחנו מתאמנים 8 פעמים בשבוע (ואם לא מגיעים – מסמסים!).</p>
                    <p>אצלנו בלומפיני אנחנו משפחה. לא “כמו משפחה” אלא באמת משפחה. ללא הבדל דת, גזע או מין. </p>
                    <p>אצלנו בלומפיני אנחנו עושים איגרוף תאילנדי. בלי פוזות, בלי מניירות. איגרוף תאילנדי.</p>
                </span>
            </div>
        </div>
    )
}

export default SuitsMe