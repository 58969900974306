import React, { useRef, useState } from "react";
import "./Registration.css";
import RegistrationHeader from "../../data/image/registration/RegistrationHeader.png";
import RegistrationBox from "../../data/image/registration/RegistrationBox.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import emailjs from "@emailjs/browser";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
const Registration = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(null);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_cs9pleq",
        "template_o48cxmu",
        form.current,
        "cdfqrlv42Baz6U-jk"
      )
      .then(
        (result) => {
          setShowModal(true);

          navigate("/ThankYou");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  const handleCloseModal = () => {
    setShowModal(false);
    clearTimeout(timer);
  };
  return (
    <div className="registration">
      <div className="input">
        <img src={RegistrationHeader} />
        <Form ref={form} onSubmit={sendEmail}>
          <h1 className="registration_text">
            מלאו את הטופס לקביעת האימון הראשון
          </h1>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              required
              type="name"
              name="fullName"
              placeholder="שם מלא"
              id="fullName"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              required
              type="email"
              placeholder="אימייל"
              id="email"
              name="email"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Control
              required
              type="phone"
              placeholder="מספר פלאפון"
              id="phone"
              name="phone"
            />
          </Form.Group>

          <div className="d-grid gap-1">
            <Button
              variant="primary"
              value="Send"
              size="lg"
              type="submit"
              style={{ backgroundColor: "#d1c709", border: "none" }}
            >
              כן בועז , אני רוצה לבוא להתאמן!
            </Button>
          </div>
        </Form>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          <p className="modal-message">
            הודעה נשלחה בהצלחה! <br />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            סגור
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Registration;
