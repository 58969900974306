import React from 'react'
import "./Questions.css"
import questions from "../../data/image/questions/2.jpeg"
import Accordion from 'react-bootstrap/Accordion';

const Questions = () => {
    return (
        <div className='questions'>
            <div className='header_questions'>
                <h1>שאלות ותשובות</h1>
                <div className="elementor-widget"><div className="elementor-divider"><span className="elementor-divider-separator"></span></div></div>
            </div>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>אין לי ידע קודם, זה מתאים לי?</Accordion.Header>
                    <Accordion.Body>
                        אין צורך בידע קודם, אנחנו מלמדים הכל מאפס.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>אני מתחת לגיל 18, זה מתאים לי?</Accordion.Header>
                    <Accordion.Body>
                        בוודאי! האימונים מתאימים לכל הגילאים החל מגיל 11:)
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>צריך להגיע בכושר?</Accordion.Header>
                    <Accordion.Body>
                        התשובה היא לא. לאט ובהדרגה נכנסים לכושר אימון, כל אחד בקצב שלו בלי לחץ.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>אני קטן ולא חזק האם זה מתאים לי?</Accordion.Header>
                    <Accordion.Body>
                        בוודאי! בתוך תקופת אימון מאוד קצרה אתה תצחק על השאלה הזאת.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>חוטפים מכות באימון?</Accordion.Header>
                    <Accordion.Body>
                        לא חוטפים מכות. עובדים על טכניקה, בלי כוח ובעיקר על הגנות.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>נפצעים באימון?</Accordion.Header>
                    <Accordion.Body>
                        לא חוטפים מכות. עובדים על טכניקה, בלי כוח ובעיקר על הגנות.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>אם ארצה להתחרות,יש תחרויות?</Accordion.Header>
                    <Accordion.Body>
                        כן. אינטרקלאב, אליפות ישראל, אירופה ועולם למתאימים.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>תוך כמה זמן אני אהיה מסוגל להגן על כצמי?</Accordion.Header>
                    <Accordion.Body>
                        בדרך כלל בין חצי שנה לשנה ברמת הרחוב, כמובן שזה תלוי רק בך: )


                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>לכמה אימונים מומלץ להגיע בשבוע?</Accordion.Header>
                    <Accordion.Body>
                        בין 2 ל 3 אימונים זה מעולה!

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>אני מעוניין! כמה זה עולה?</Accordion.Header>
                    <Accordion.Body>
                        אימון חד פעמי עולה 40 ש”ח לבגור 20 ש״ח לנוער. מנוי חודשי שמאפשר לך להגיע לכל אימונים השבוע עולה 350 ש”ח בחודש.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>אני רוצה להגיע לאימון ניסיון ולהמשיך למינוי חודשי,זה מתקזז?</Accordion.Header>
                    <Accordion.Body>
                        כן! לאחר אימון הניסיון קיימת האפשרות להירשם למנוי חודשי, במידה והחלטת לרכוש מנוי המחיר מתקזז מהשיעור. כלומר 40 ש”ח – 350 ש”ח = 310 ש”ח להמשך המנוי:)


                    </Accordion.Body>
                </Accordion.Item>



            </Accordion>
        </div>
    )
}

export default Questions