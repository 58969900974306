import React from 'react'
import "./HeaderButton.css"
import { Icon } from '@iconify/react';

const HeaderButton = () => {
    return (
        <section className='button_header'>

            <button className='on_button' ><a href='#registration'>
                <span >לחץ כאן לקביעת אימון</span>


                <Icon className='iconbox' icon="noto:boxing-glove" width="140" height="85" rotate={1} />
            </a>

            </button>
        </section>

    )
}

export default HeaderButton