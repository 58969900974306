import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function AccessibilityStatement(props) {
    return (
        <div><Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='accessibilit'>
                    <h2>      הצהרת נגישות</h2>
                    <p>
                        <h3> סטטוס נגישות</h3>
                        <p>אנו מאמינים כי האינטרנט צריך להיות זמין ונגיש לכל אחד, ורצוננו הוא לספק אתר אינטרנט שנגיש לקהל הרחב ביותר, ללא קשר לנסיבות וליכולת. בכדי לממש זאת, אנו שואפים לדבוק בקפדנות ככל האפשר בהנחיות הנגישות לתוכן ברשת האינטרנט World Wide Web Consortium (W3C) ברמת AA. הנחיות אלה מסבירות כיצד להנגיש תוכן באינטרנט לאנשים עם מגוון רחב של מוגבלויות. עמידה בהנחיות אלו מסייעת לנו להבטיח כי האתר נגיש לכל האנשים: אנשים עיוורים, אנשים עם לקויות מוטוריות, ליקוי ראייה, מוגבלות קוגניטיבית ועוד.</p>
                        <p>אתר זה עושה שימוש בטכנולוגיות שונות שנועדו להפוך אותו לנגיש ככל האפשר בכל עת. אנו משתמשים בממשק נגישות המאפשר לאנשים עם מוגבלות ספציפית להתאים את ממשק המשתמש של האתר (ממשק המשתמש) ולעצב אותו לצרכים האישיים שלהם. ממשק זה מחליף את הגישה המיושנת לנגישות בה מוצגים לכל המשתמשים אותו העיצוב הבינוני.</p>
                        <p>בנוסף, האתר משתמש ביישום מבוסס AI הפועל ברקע ומייעל את רמת הנגישות שלו ללא הרף. יישום זה מתקן את בעיות ה- HTML של האתר, מתאים את הפונקציונליות וההתנהגות של האתר לקוראי מסך המשמשים את המשתמשים העיוורים ופונקציות מקלדת המשמשות אנשים עם לקויות מוטוריות.</p>
                        <h3> קורא מסך וניווט במקלדת באתר האינטרנט שלנו</h3>
                        <p>

                            האתר שלנו מיישם את טכניקת תכונות ה- ARIA (יישומי אינטרנט עשירים נגישים), לצד שינויים שונים בהתנהגות, על מנת להבטיח שמשתמשים עיוורים המבקרים אצל קוראי מסך יוכלו לקרוא, להבין ולהנות מפונקציות האתר שלך. ברגע שמשתמש עם קורא-מסך נכנס לאתר שלך, הם מייד מקבלים הנחיה להיכנס לפרופיל קורא-המסך כדי שיוכלו לגלוש ולהפעיל את האתר בצורה יעילה. כך האתר שלנו מכסה כמה מהדרישות החשובות ביותר של קוראי מסך, לצד צילומי מסך של דוגמאות לקוד:
                            <h4>אופטימיזציה של קוראי מסך:</h4>
                            <p>אנו מנהלים תהליך רקע שלומד את רכיבי האתר מלמעלה למטה, כדי להבטיח תאימות שוטפת גם בעת עדכון האתר. בתהליך זה אנו מספקים לקוראי המסך נתונים משמעותיים באמצעות מערך התכונות של ARIA. לדוגמה, אנו מספקים תוויות טופס מדויקות; תיאורים לסמלים הניתנים לפעולה (סמלי מדיה חברתית, סמלי חיפוש, סמלי עגלה וכו ‘); הנחיות אימות לתשומות טפסים; תפקידי אלמנטים כגון כפתורים, תפריטים, דיאלוגים מודאליים (חלונות קופצים) ואחרים.

                            </p>
                            <p>
                                בנוסף, תהליך הרקע סורק את כל תמונות האתר ומספק תיאור מדויק ומשמעותי מבוסס זיהוי-אובייקט תמונה כתג ALT (טקסט חלופי) לתמונות שאינן מתוארות. זה גם יחלץ טקסטים המוטמעים בתמונה, באמצעות טכנולוגיית OCR (זיהוי תווים אופטי). כדי להפעיל התאמות של קוראי מסך בכל עת, המשתמשים צריכים רק ללחוץ על שילוב המקשים Alt + 1. משתמשי קוראי מסך מקבלים גם הודעות אוטומטיות להפעלת מצב קורא המסך ברגע שהם נכנסים לאתר.
                            </p>
                            <p>
                                התאמות אלה תואמות את כל קוראי המסכים הפופולריים כולל JAWS ו- NVDA.
                            </p>
                            <p>
                                מיטוב ניווט במקלדת: תהליך הרקע מתאים גם את ה- HTML של האתר ומוסיף התנהגויות שונות באמצעות קוד JavaScript כדי להפוך את האתר להפעלה באמצעות המקלדת. זה כולל את היכולת לנווט באתר באמצעות מקשי Tab ו- Shit + Tab, להפעיל נפתחות עם מקשי החצים, לסגור אותם באמצעות Esc, לחצני הדק וקישורים באמצעות מקש Enter, לנווט בין רכיבי רדיו לתיבת סימון באמצעות מקשי החצים, מלא אותם באמצעות מקש הרווח או מקש Enter.
                            </p>
                            <p>
                                בנוסף, משתמשים במקלדת ימצאו תפריטים לניווט מהיר ודילוג על תוכן, הזמינים בכל עת על ידי לחיצה על Alt + 1, או כאל האלמנטים הראשונים באתר תוך ניווט עם המקלדת. תהליך הרקע מטפל גם בקפיצות המופעלות על ידי הזזת מיקוד המקלדת לכיוונם ברגע שהם מופיעים, ולא מאפשרים להתמקד במיקוד החוצה ממנה.
                            </p>
                            <p>
                                משתמשים יכולים גם להשתמש בקיצורי דרך כמו “M” (תפריטים), “H” (כותרות), “F” (טפסים), “B” (כפתורים) ו- “G” (גרפיקה) כדי לקפוץ לאלמנטים ספציפיים.
                                התאמות ממשק המשתמש, העיצוב והקריאות הקיימות באתר האינטרנט שלנו
                                התאמות גופן – משתמשים יכולים להגדיל ולהקטין את גודלו, לשנות את משפחתו (סוג), להתאים את המרווח, היישור, גובה הקו ועוד.
                                <p>
                                    <strong>  התאמות צבע</strong> – המשתמשים יכולים לבחור פרופילי ניגודי צבע שונים כגון אור, כהה, הפוך ושחור-לבן. בנוסף, משתמשים יכולים להחליף ערכות צבע של כותרות, טקסטים ורקעים, עם מעל 7 אפשרויות צביעה שונות.
                                </p>
                                <p>
                                    <strong>   אנימציות    </strong>– משתמשים באפילפסיה יכולים לעצור את כל ההנפשות המפעילות בלחיצת כפתור. הנפשות הנשלטות על ידי הממשק כוללות קטעי וידאו, GIF ומעברים מהבהבים של CSS.
                                </p>
                                <p>

                                    <strong>  הדגשת תוכן   </strong> – המשתמשים יכולים לבחור להדגיש אלמנטים חשובים כמו קישורים וכותרות. הם יכולים גם לבחור להדגיש אלמנטים ממוקדים או מרחפים בלבד.
                                </p>
                                <p>

                                    <strong>   השתקת שמע </strong> – משתמשים עם מכשירי שמיעה עלולים לחוות כאבי ראש או בעיות אחרות בגלל השמעת שמע אוטומטית. אפשרות זו מאפשרת למשתמשים להשתיק את האתר כולו באופן מיידי.
                                </p>
                                <p>
                                    <strong>   הפרעות קוגניטיביות  </strong>  – אנו משתמשים במנוע חיפוש המקושר לוויקיפדיה ולוויקימילון, ומאפשר לאנשים עם הפרעות קוגניטיביות לפענח משמעויות של ביטויים, ראשי תיבות, סלנג ואחרים.
                                </p>
                                <p>
                                    <strong>     פונקציות נוספות      </strong> – אנו מספקים למשתמשים אפשרות לשנות את צבע וסמן הסמן, להשתמש במצב הדפסה, לאפשר מקלדת וירטואלית ופונקציות רבות אחרות.
                                </p>

                            </p>
                            <h4>תאימות דפדפן ותמיכה טכנולוגית</h4>
                            <p>אנו שואפים לתמוך במערך הרחב של הדפדפנים והטכנולוגיות המסייעות ככל האפשר, כך שהמשתמשים שלנו יוכלו לבחור בכלים המתאימים ביותר עבורם, עם כמה שפחות מגבלות. לכן, עבדנו קשה מאוד כדי להיות מסוגלים לתמוך בכל המערכות העיקריות המהוות מעל 95% מנתח שוק המשתמשים כולל גוגל כרום, מוזילה פיירפוקס, אפל ספארי, אופרה ומיקרוסופט אדג ‘, JAWS ו- NVDA (קוראי מסך), שניהם עבור חלונות ומשתמשי MAC.</p>
                            <h4>הערות, הערות ומשוב
                            </h4>
                            <p>למרות המאמצים הטובים ביותר שלנו לאפשר לכל אחד להתאים את האתר לצרכיו, עדיין עשויים להיות דפים או קטעים שאינם נגישים במלואם, נמצאים בתהליך הנגשה או חסרים להם פיתרון טכנולוגי הולם כדי להנגיש אותם. ועדיין, אנו משפרים ללא הרף את הנגישות שלנו, מוסיפים, מעדכנים ומשפרים את האפשרויות והתכונות שלה ופיתוח ואימוץ טכנולוגיות חדשות. כל זה נועד להגיע לרמת הנגישות האופטימלית, בעקבות התקדמות טכנולוגית.

                            </p>
                        </p>
                    </p>
                </div>
            </Modal.Body>

        </Modal>
        </div>
    )
}

export default AccessibilityStatement