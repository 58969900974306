import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./pages/Home/Home";
import Registration from "./pages/registration/Registration";
import AboutMe from "./pages/aboutme/AboutMe";
import SuitsMe from "./pages/suitsme/SuitsMe";
import TrainingSchedule from "./pages/trainingschedule/TrainingSchedule";
import StartNow from "./pages/startnow/StartNow";
import Questions from "./pages/questions/Questions";
import EndSite from "./pages/endsite/EndSite";
import NavBar from "./components/navbar/NavBar";
import { Icon } from "@iconify/react";
import Footer from "./components/footer/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Thanks from "./pages/thanks/Thanks";
function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <section id="home" className="page">
                  <Home />
                </section>
                <section id="registration" className="page">
                  <Registration />
                </section>
                <section id="about_me" className="page">
                  <AboutMe />
                </section>
                <section id="suitsme" className="page">
                  <SuitsMe />
                </section>
                <section id="training_board" className="page">
                  <TrainingSchedule />
                </section>
                <section id="startnow" className="page">
                  <StartNow />
                </section>
                <section id="questions" className="page">
                  <Questions />
                </section>
                <section id="endsite" className="page">
                  <EndSite />
                </section>
              </>
            }
          />
          <Route path="/thankyou" element={<Thanks />} />
        </Routes>
        <Footer />
      </Router>
    </>
    // <div>
    //   <NavBar />

    //   <section id="home" className="page">
    //     <Home />
    //   </section>
    //   <section id="registration" className="page">
    //     <Registration />
    //   </section>
    //   <section id="about_me" className="page">
    //     <AboutMe />
    //   </section>
    //   <section id="suitsme" className="page">
    //     <SuitsMe />
    //   </section>
    //   <section id="training_board" className="page">
    //     <TrainingSchedule />
    //   </section>
    //   <section id="startnow" className="page">
    //     <StartNow />
    //   </section>
    //   <section id="questions" className="page">
    //     <Questions />
    //   </section>
    //   <section id="endsite" className="page">
    //     <EndSite />
    //   </section>
    //   <Footer />
    // </div>
  );
}

export default App;
