import React from 'react'
import GoogleMapReact from 'google-map-react';
import { Icon } from '@iconify/react';

const MapGivatiom = () => {



    return (
        <section>


            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe
                        className="gmap_iframe"
                        frameBorder={0}
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                        src="https://maps.google.com/maps?width=691&height=750&hl=en&q=גבעתיים המאור 13&t=&z=18&ie=UTF8&iwloc=B&output=embed"
                    />
                </div>
                <span className='contactsPlace' style={{ "color": "white", "display": "flex" }}><Icon icon="arcticons:map" color="#d1c70b" width="30" height="30" />
                    <p >

                        מיקום: רחוב המאור 13, גבעתיים

                    </p>
                </span>
            </div>
        </section>

    )
}

export default MapGivatiom