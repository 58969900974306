
export const links = [
    {
        id: 1,
        text: "קצת עלי",
        url: '#about_me'
    },
    {
        id: 2,
        text: "לוח אימונים",
        url: '#training_board'
    },

    {
        id: 3,
        text: "הרשמה",
        url: '#startnow'
    },
    {
        id: 4,
        text: "שאלות תשובות",
        url: '#questions'
    },
    {
        id: 5,
        text: "צור קשר",
        url: '#endsite'
    },




]