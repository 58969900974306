import React, { useState } from 'react'
import Icons from '../../pages/endsite/Icons'
import "./Footer.css"
import { Icon } from '@iconify/react';
import logo from "../../data/image/logo/logo.jpeg"
import AccessibilityStatement from './AccessibilityStatement';
import PrivacyPolicy from "./PrivacyPolicy"
const Footer = () => {
    const [modalShow, setModalShow] = useState(false);
    const [modalShowPoblic, setModalShowPoblic] = useState(false);
    return (
        <footer>
            <div className="content">
                <div className="left box">
                    <div className="upper">


                    </div>
                    <div className="lower">
                        <div className="topic">צור קשר</div>
                        <div className="phone">
                            <a href="#">

                                <Icon icon="ant-design:phone-filled" />

                                0545760576
                            </a>
                        </div>

                        <div className="email">
                            <a href="#">

                                <Icon icon="dashicons:email-alt" />
                                <span />
                                boazpolani@gmail.com
                            </a>
                        </div>

                    </div>
                </div>
                <div className="middle box">

                    <div>
                        <a href="#" onClick={() => setModalShow(true)}>הצהרת נגישות</a>
                    </div>
                    <div>
                        <a href="#" onClick={() => setModalShowPoblic(true)}>מדיניות פרטיות </a>
                    </div>

                </div>
                <div className="right box">

                    <form action="#">


                    </form>
                </div>
            </div>
            <div className="bottom">
                <div className="media-icons">

                    <Icons />
                </div>
                <p>
                    Copyright © 2022 <a href="https://www.oshriwd.com" target="_blank" rel="noreferrer">OshriWd</a> All rights reserved
                </p>
            </div>
            <AccessibilityStatement

                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <PrivacyPolicy

                show={modalShowPoblic}
                onHide={() => setModalShowPoblic(false)}
            />

        </footer >

    )
}

export default Footer