import "./Thanks.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Thanks = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(4);

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/"); // Replace "/home" with the desired path of your home screen
    }, 5000);

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [navigate]);

  return (
    <div className="thanks">
      <h1>
        ההודעה נשלחה בהצלחה תודה<hr></hr> ניצור איתך קשר בהקדם
      </h1>
      <h2> {countdown}</h2>
    </div>
  );
};

export default Thanks;
