import React from "react";
import "./TrainingSchedule.css";
import headerschedule from "../../data/image/trainingschedule/1.png";
import bpxinggloves from "../../data/image/trainingschedule/boxing-gloves.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Icon } from "@iconify/react";

const TrainingSchedule = () => {
  return (
    <div className="training_schedule">
      <div className="header_training_schedule">
        <img src={headerschedule} className="trainingschedule_img" />
        <h2>לוח אימונים</h2>
      </div>

      <div className="grid_training_schedule">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              {/* Bootstrap 5 card box */}
              <div className="card-box">
                <div className="card-thumbnail">
                  <img src={bpxinggloves} className="img-fluid" alt="" />{" "}
                </div>
                <h3>
                  <a href="#" className="mt-2 text-danger">
                    יום ראשון
                  </a>
                </h3>
                <p className="text-secondary">
                  <p className="date">
                    <p>18:00 – 19:00 – ונוער</p>

                    <p>19:00 – 20:15 – בוגרים מתחילים</p>

                    <p>20:15 - 21:30 – בוגרים מתקדמים</p>
                  </p>
                  <div className="place">
                    <Icon
                      icon="arcticons:baidu-map"
                      color="#ffb510 "
                      width="30"
                      height="30"
                    />
                    <span className="text_header">
                      מיקום:סניף דיזנגוף סנטר{" "}
                    </span>
                  </div>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              {/* Bootstrap 5 card box */}
              <div className="card-box">
                <div className="card-thumbnail">
                  <img src={bpxinggloves} className="img-fluid" alt="" />{" "}
                </div>
                <h3>
                  <a href="#" className="mt-2 text-danger">
                    יום שני
                  </a>
                </h3>
                <p className="text-secondary">
                  <p className="date">
                    <p>09:30 - 10:45 – בוגרים מעורב</p>

                    <p>16:30 - 17:30– נוער</p>

                    <p>19:30 - 20:15 – בוגרים מעורב</p>
                    <p>20:15 - 21:15– בוגרים מעורב</p>
                  </p>
                  <div className="place">
                    <Icon
                      icon="arcticons:baidu-map"
                      color="#ffb510 "
                      width="30"
                      height="30"
                    />
                    <span className="text_header">
                      מיקום:סניף דיזנגוף סנטר{" "}
                    </span>
                  </div>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              {/* Bootstrap 5 card box */}
              <div className="card-box">
                <div className="card-thumbnail">
                  <img src={bpxinggloves} className="img-fluid" alt="" />
                </div>
                <h3>
                  <a href="#" className="mt-2 text-danger">
                    יום שלישי
                  </a>
                </h3>
                <p className="text-secondary">
                  <p className="date">
                    <p>18:00 – 19:00 – נוער</p>

                    <p>19:00 – 20:15 – בוגרים מעורב</p>
                  </p>
                  <div className="place">
                    <Icon
                      icon="arcticons:baidu-map"
                      color="#ffb510 "
                      width="30"
                      height="30"
                    />
                    מיקום: סניף גבעתיים, רחוב המאור 13
                  </div>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              {/* Bootstrap 5 card box */}
              <div className="card-box">
                <div className="card-thumbnail">
                  <img src={bpxinggloves} className="img-fluid" alt="" />{" "}
                </div>
                <h3>
                  <a href="#" className="mt-2 text-danger">
                    יום רביעי
                  </a>
                </h3>
                <p className="text-secondary">
                  <p className="date">
                    <p>18:00 – 19:00 – ונוער</p>

                    <p>19:00 - 20:15– בוגרים מתקדמים</p>

                    <p>20:15 - 21:30– בוגרים מתחילים</p>
                  </p>
                  <div className="place">
                    <Icon
                      icon="arcticons:baidu-map"
                      color="#ffb510 "
                      width="30"
                      height="30"
                    />
                    <span className="text_header">
                      מיקום:סניף דיזנגוף סנטר{" "}
                    </span>
                  </div>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card-box">
                <div className="card-thumbnail">
                  <img src={bpxinggloves} className="img-fluid" alt="" />{" "}
                </div>

                <h3>
                  <a href="#" className="mt-2 text-danger">
                    יום חמישי
                  </a>
                </h3>
                <p className="text-secondary">
                  <p className="date">
                    <p>09:30 - 10:45– בוגרים מעורב</p>
                    <p>16:30 - 17:30–מזרון חופשי</p>
                    <p>19:00 - 20:15– נוער</p>
                  </p>

                  <div className="place">
                    <Icon
                      icon="arcticons:baidu-map"
                      color="#ffb510 "
                      width="30"
                      height="30"
                    />{" "}
                    <span className="text_header">
                      מיקום:סניף דיזנגוף סנטר{" "}
                    </span>
                  </div>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              {/* Bootstrap 5 card box */}
              <div className="card-box">
                <div className="card-thumbnail">
                  <img src={bpxinggloves} className="img-fluid" alt="" />
                </div>
                <h3>
                  <a href="#" className="mt-2 text-danger">
                    יום שישי
                  </a>
                </h3>
                <p className="text-secondary">
                  <p className="date">
                    <p>14:00 – 15:00 – נוער</p>

                    <p>15:00 – 16:15 – בוגרים מעורב</p>
                  </p>
                  <div className="place">
                    <Icon
                      icon="arcticons:baidu-map"
                      color="#ffb510 "
                      width="30"
                      height="30"
                    />
                    מיקום: סניף גבעתיים, רחוב המאור 13
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingSchedule;
