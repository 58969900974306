/* eslint-disable jsx-a11y/alt-text */
import "./NavBar.css"
import { links } from "../../data/Data"
import { useEffect, useState } from "react";

import logo from "../../data/image/logo/logo.jpeg"

// import logo from "../../image/logo/logo.jpeg"


import { Icon } from '@iconify/react';
import Icons from "../../pages/endsite/Icons";

const NavBar = () => {
    const useClick = (e) => {
        e.preventDefault();
        const target = e.target.getAttribute('href')
        const location = document.querySelector(target).offsetTop
        console.log(location)
        // const target = useRef(null);
        window.scrollTo({
            left: 0,
            top: location - 100,
        })

    }
    const [navLinkOpen, setNavLinkOpen] = useState(false)
    const handleNavToggle = () => {
        setNavLinkOpen(!navLinkOpen)
    }
    const renderClasses = () => {
        let classes = "navlinks"

        if (navLinkOpen) {
            classes += " active"
        }
        return classes

    }
    return (


        <nav>




            <div onClick={handleNavToggle} className="hum">
                <Icon icon="charm:menu-hamburger" color="white" width="40" height="40" />

            </div>
            <ul className={renderClasses()}>
                {
                    links.map((link) => (


                        <li className="link"><a href={link.url} key={link.id} onClick={useClick}>{link.text}</a></li>

                    ))
                }
            </ul>
            <div className="icon_nav">
                <Icons />
            </div>



            <img className="logo" src={logo} />

        </nav>



    )
}

export default NavBar