import React from "react";
import "./Home.css";
import { FaWhatsapp } from "react-icons/fa";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import HeaderButton from "../../components/buttons/header_ button/HeaderButton";
const Home = () => {
  return (
    <div className="home">
      <div
        className="phone-call cbh-phone cbh-green cbh-show  cbh-static"
        id="clbh_phone_div"
        style={{}}
      >
        <a
          id="WhatsApp-button"
          href="https://api.whatsapp.com/send?phone=545760576&text=%D7%91%D7%95%D7%A2%D7%96,%20%D7%90%D7%A0%D7%99%20%D7%A8%D7%95%D7%A6%D7%94%20%D7%A9%D7%AA%D7%90%D7%9E%D7%9F%20%D7%90%D7%95%D7%AA%D7%99"
          target="_blank"
          className="phoneJs"
          title="WhatsApp 360imagem"
          rel="noreferrer"
        >
          <div className="cbh-ph-circle" />
          <div className="cbh-ph-circle-fill" />
          <div className="cbh-ph-img-circle1"></div>
        </a>
      </div>

      <video
        autoplay="autoplay"
        loop="loop"
        muted
        defaultMuted
        playsInline
        id="myVideo"
      >
        <source
          src="https://firebasestorage.googleapis.com/v0/b/lumpinee-429bb.appspot.com/o/finalmovieheader.mp4?alt=media&token=1a040afe-6e37-40e7-97e5-5f51e15292eb"
          type="video/mp4"
        />
      </video>
      <div className="row title">
        <div className="col-md-18">
          <h1 className="events "> רוצה לדעת איך להגן על עצמך?</h1>
          <h1 className="events ">הגיע הזמן ללמוד </h1>
          <h1 className="events header">
            {" "}
            <span className="snip"> איגרוף תאילנדי!</span>
          </h1>
          <h1 className="events bu">
            <HeaderButton />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Home;
